import {Component, Inject, OnInit, Renderer2}             from '@angular/core';
import {ActivatedRoute, Router, RouterLink, RouterOutlet} from "@angular/router";
import {CommonModule, DOCUMENT}                           from "@angular/common";
import {HttpClient, HttpClientModule, HttpErrorResponse}  from "@angular/common/http";
import {ComponentsModule}                                 from "../../components/components.module";
import {Environment, ENVIRONMENT, Theme}                  from "../../models/util";
import {environment}                                      from "../../../environments/environment";
import {InputTextModule}                                  from "primeng/inputtext";
import {ButtonModule}                                     from "primeng/button";
import {RippleModule}                                     from "primeng/ripple";
import {InputSwitchModule}                                from "primeng/inputswitch";
import {MultiSelectModule}                                from "primeng/multiselect";
import {Meta, Title}                                      from "@angular/platform-browser";
import {Page}                                             from "../../components/page";
import {Job, JobResponse}                                 from "../../models/generic";
import {ToggleButtonModule}                               from "primeng/togglebutton";
import {AccordionModule}                                  from "primeng/accordion";
import {AvatarModule}                                     from "primeng/avatar";
import {TagModule}                                        from "primeng/tag";
import {MessageModule}                                    from "primeng/message";
import {MessagesModule}                                   from "primeng/messages";
import {FormsModule}                                      from "@angular/forms";
import {DialogModule}                                     from "primeng/dialog";
import {TranslateModule, TranslateService}                from "@ngx-translate/core";
import {SidebarModule}                                    from "primeng/sidebar";
import {GoogleTagManagerService}                          from "../../services/gtm.service";
import {PageComponentModel}                               from "../../models/page";

@Component({
    selector   : 'app-assignments',
    standalone : true,
    imports    : [
        RouterLink,
        CommonModule,
        RouterOutlet,
        HttpClientModule,
        ComponentsModule,
        InputTextModule,
        ButtonModule,
        RippleModule,
        InputSwitchModule,
        MultiSelectModule,
        ToggleButtonModule,
        AccordionModule,
        AvatarModule,
        TagModule,
        MessageModule,
        MessagesModule,
        FormsModule,
        DialogModule,
        TranslateModule,
        SidebarModule,
    ],
    providers  : [
        {
            provide : ENVIRONMENT,
            useValue: environment
        },
    ],
    templateUrl: './assignments.component.html',
    styles     : `
      ::ng-deep {
        .p-multiselect-label {
          padding: 0.25rem 0.25rem;
        }

        .p-multiselect-label.p-placeholder {
          padding: 0.75rem 0.75rem !important;
        }

        .p-multiselect-header {
          justify-content: end;
        }

        b {
          color: #212121;
        }

        u {
          text-decoration: none;
        }
      }
    `
})
export class AssignmentsComponent extends Page implements OnInit {
    theme            = new Theme('light');
    endpoint: string = 'assignments';

    query: string = '';

    clients: { name: string, value: any }[] = [];
    selectedClient: any[]                   = [];

    locations: { name: string, value: any }[] = [];
    selectedLocation: any[]                   = [];

    hoursPerWeek: { name: string, value: any }[] = [
        {name: '40u', value: '40'},
        {name: '36u', value: '36'},
        {name: '32u', value: '32'},
        {name: '28u', value: '28'},
        {name: '24u', value: '24'},
        {name: '20u', value: '20'},
        {name: '16u', value: '16'},
        {name: '12u', value: '12'},
        {name: '8u', value: '8'},
    ];
    selectedHour: any[]                          = [];

    currentJob: Job | null          = null;
    jobResponse: JobResponse | null = null;
    messages                        = <any>[
        {
            severity: 'info',
            summary : this.translateService.instant('NO_CURE_NO_PAY_SUMMARY'),
            detail  : this.translateService.instant('NO_CURE_NO_PAY_MESSAGE')
        },
    ];

    mobileFilterOn = false;

    constructor(
        @Inject(DOCUMENT) override readonly document: Document,
        @Inject(ENVIRONMENT) override readonly environment: Environment,
        override readonly gtm: GoogleTagManagerService,
        override readonly meta: Meta,
        override readonly title: Title,
        override readonly router: Router,
        override readonly httpClient: HttpClient,
        override readonly translateService: TranslateService,
        private renderer2: Renderer2,
        private activatedRoute: ActivatedRoute,
    ) {
        super(document, environment, gtm, meta, title, router, httpClient, translateService);
    }

    override pageView(page: PageComponentModel) {
        return {
            ...super.pageView(page),
            page_type: 'opdrachten',
        };
    }

    returnUrl(): string {
        const [locale, ...path] = this.router.url
            .split('/')
            .slice(1);

        return `?lang=${locale}`;
    }

    override ngOnInit() {
        super.ngOnInit();

        this.getClients();
        this.getLocations();

        this.activatedRoute.queryParams.subscribe(params => {
            Object.keys(params).forEach(p => {
                if (p === 'id') {
                    this.viewJob(params[p])
                }
                if (p === 'query') {
                    this.query = params[p];
                }
                if (p === 'hours_per_week_max') {
                    this.selectedHour = [params[p]];
                }
                if (p === 'client_name_slug') {
                    this.selectedClient = [params[p]];
                }
                if (p === 'location_slug') {
                    this.selectedLocation = [params[p]];
                }
            });

            this.searchJobs((!('id' in params)))
        });
    }

    filter() {
        this.router.navigate(['.'], {
            relativeTo : this.activatedRoute,
            queryParams: this.returnQueryParams()
        });
    }

    redirectLogin() {
        if (this.currentJob) {
            window.open(`https://login.striive.com?origin=/inbox/all/${this.currentJob.recordId}`, '_blank');
        } else {
            window.open(`https://freelancer.striive.com/`, '_blank');
        }
    }

    clearCurrentJob() {
        this.currentJob = null;
    }

    searchJobs(resetCurrent = true) {
        const params          = {...this.returnQueryParams(), open_or_closed: true};
        const encodedParams   = Object.keys(params).map(key => `${encodeURIComponent(key)}=${encodeURIComponent(params[key])}`).join('&');
        const dataLayerParams = this.returnQueryParams();

        this.httpClient.get<JobResponse>(`${this.environment.apiUrl}jobs?${encodedParams}`)
            .subscribe({
                next : (jobResponse) => {
                    this.jobResponse = jobResponse;

                    Object.keys(dataLayerParams).forEach(key => {
                        this.gtm.trackEvent({
                            event       : "filter",
                            result      : this.jobResponse?.total,
                            filter_type : "search",
                            filter_name : key,
                            filter_value: dataLayerParams[key],
                        });
                    });

                    if (resetCurrent) {
                        this.currentJob = null;
                    }
                },
                error: (err: HttpErrorResponse) => {
                },
            });
    }

    viewJob(id: string) {
        this.httpClient.get<Job>(`${this.environment.apiUrl}jobs?type=single&id=${id}`)
            .subscribe({
                next : (job) => {
                    this.currentJob = job;
                    this.trackWithJob();
                    this.setJobPostingJSON();
                },
                error: (err: HttpErrorResponse) => {
                },
            });
    }

    private getLocations() {
        this.httpClient.get<{ [value: string]: string }>(`${this.environment.apiUrl}jobs?type=locations`)
            .subscribe({
                next : (locations) => {
                    this.locations = Object.keys(locations).map(l => ({
                        name : locations[l],
                        value: l,
                    }));
                },
                error: (err: HttpErrorResponse) => {
                },
            });
    }

    private getClients() {
        this.httpClient.get<{ [value: string]: string }>(`${this.environment.apiUrl}jobs?type=clients`)
            .subscribe({
                next : (clients) => {
                    this.clients = Object.keys(clients).map(c => ({
                        name : clients[c],
                        value: c,
                    }));
                },
                error: (err: HttpErrorResponse) => {
                },
            });
    }

    private returnQueryParams() {
        let queryParams: any = {};
        if (this.query) {
            queryParams['query'] = this.query;
        }
        if (this.selectedHour.length > 0) {
            queryParams['hours_per_week_max'] = (this.selectedHour[0]);
        }
        if (this.selectedClient.length > 0) {
            queryParams['client_name_slug'] = (this.selectedClient[0]);
        }
        if (this.selectedLocation.length > 0) {
            queryParams['location_slug'] = (this.selectedLocation[0]);
        }

        return queryParams;
    }

    createAccountClick() {
        this.gtm.trackEvent({
            event: 'registration_redirect'
        });
    }

    jobApply() {
        this.trackWithJob('job_apply');
    }

    addFavorite() {
        this.trackWithJob('job_add_to_favorites')
    }

    setJobPostingJSON() {
        if (!this.currentJob) {
            return;
        }

        let script = this.renderer2.createElement('script');
        script.type = `application/ld+json`;
        script.text = `
                {
                    "@context" : "https://schema.org/",
                    "@type" : "JobPosting",
                    "title" : ${this.currentJob.title},
                    "description" : ${this.currentJob.content},
                    "identifier": {
                        "@type": "PropertyValue",
                        "name": ${this.currentJob.source},
                        "value": ${(this.currentJob.referenceCode ? this.currentJob.referenceCode : this.currentJob.referenceCodeClient)}
                    },
                    "datePosted" : "2024-01-18",
                    "validThrough" : ${this.currentJob.closingDateClient},
                    "employmentType" : "CONTRACTOR",
                    "hiringOrganization" : {
                        "@type" : "Organization",
                        "name" : ${this.currentJob.clientName},
                        "logo" : "data:image/jpeg;base64,${this.currentJob.clientLogo}"
                    },
                    "jobLocation": {
                        "@type": "Place",
                        "address": {
                            "@type": "PostalAddress",
                            "addressLocality": ${this.currentJob.location},
                            "addressCountry": "NL"                            
                        }
                    },
                } 
        `;

        this.renderer2.appendChild(this.document.body, script);
    }

    trackWithJob(event = 'view_job_item') {
        if (!this.currentJob) {
            return;
        }

        this.gtm.trackEvent({
            event,
            job_id        : this.currentJob.referenceCode,
            client        : this.currentJob.clientName,
            job_name      : this.currentJob.title,
            end_date      : this.currentJob.endDate,
            location      : this.currentJob.location,
            start_date    : this.currentJob.startDate,
            week_hours    : this.currentJob.hoursPerWeekMax,
            closed_date   : this.currentJob.closingDateClient,
            contract_label: this.currentJob.source,
            published_date: this.currentJob.publishedDate,
        })
    }
}
