<div [class]="(blockClass ? (blockClass + ' ') : '') + theme.current.card + (block.class_overwrite ? (' ' + block.class_overwrite) : '' )">
    <div class="container m-auto relative overflow-hidden px-3 lg:px-0">
        <app-seaweed *ngIf="block.background_seaweed" [alignment]="block.seaweed_alignment.value"
                     [theme]="theme"></app-seaweed>

        <div class="pt-7 md:px-6 lg:px-8 xl:!px-42">
            <div class="relative">
<!--                <div class="h-[250px] lg:h-[670px] relative lg:absolute w-full mb-3 lg:mb-0 z-0 lg:-right-42">-->
<!--                    <img priority fill [ngSrc]="(block.asset_webp.url ? block.asset_webp.url : block.asset.url)" alt="Job Search Hero" class="relative object-cover rounded-3xl overflow-hidden" />-->
<!--                </div>-->

                <div class="grid relative z-1">
                    <div class="col-12 lg:col-6">
                        <app-text [isHeading]="true" [block]="block" [theme]="theme" titleSize="text-4xl"></app-text>
                    </div>

                    <div class="col-12 lg:col-8 pb-0 lg:!pr-16">
                        <div class="shadow-2 p-4 rounded-3xl -mx-3 md:mx-0"
                             style="background: linear-gradient(180deg, rgba(255, 238, 228, 0.9) 0%, rgba(255, 255, 255, 0.86) 43.5%);">
                            <ng-container *ngIf="jobHeaderSearchContent">
                                <app-search-box-header [block]="jobHeaderSearchContent"></app-search-box-header>
                            </ng-container>

                            <form class="block lg:flex mb-3">
                                <span class="p-input-icon-left w-full flex-grow-1 mr-0 md:mr-2">
                                    <i class="pi pi-search"></i>
                                    <input [placeholder]="'SEARCH_PLACEHOLDER' | translate" type="text" pInputText
                                           [ngModelOptions]="{standalone: true}"
                                           [(ngModel)]="query"
                                           class="text-sm rounded-3xl border border-gray-400 outline-none bg-none w-full block">
                                </span>

                                <button (click)="navigateSearch()"
                                        type="submit"
                                        class="font-bold no-underline rounded-3xl shrink-0 flex align-items-center justify-content-center w-full md:w-auto mt-3 lg:mt-0"
                                        pButton
                                        pRipple>
                                    {{ "SEARCH_ASSIGNMENTS" | translate }}
                                </button>
                            </form>

                            <p class="block md:hidden font-bold text-lg my-3">{{ "PREFERENCES" | translate }}</p>

                            <div class="flex align-items-center flex-wrap md:flex-nowrap">
                                <div class="mb-2 md:mb-0 mr-0 lg:mr-2 basis-full md:basis-auto text-800">{{"HOURS_PER_WEEK" | translate}}
                                </div>

                                <span (click)="selectedHour = ''"
                                      [ngClass]="{'bg-primary': selectedHour === ''}"
                                      class="border-2 rounded-full border-primary text-primary py-2.5 px-3.5 mr-2 mb-2 lg:mb-0 cursor-pointer">{{"NO_PREFERENCE" | translate}}</span>
                                <span *ngFor="let hour of hours; let last = last;"
                                      [ngClass]="{'bg-primary': selectedHour === hour.value, 'mr-0': last}"
                                      (click)="selectedHour === hour.value ? (selectedHour = '') : (selectedHour = hour.value)"
                                      class="border-2 rounded-full border-primary text-primary py-2.5 px-3.5 mr-2 mb-2 lg:mb-0 cursor-pointer">{{ hour.label }}</span>
                            </div>
                        </div>
                    </div>
                    <div class="col-12 lg:col-4 pb-0">
                        <div class="relative">

                            <div *ngFor="let job of jobs; let last = last"
                                 [ngClass]="{'mb-3': !last}"
                                 (click)="navigateJob(job.meta.id)"
                                 class="bg-white p-3 mb-3 flex border-2 surface-border shadow-2 relative cursor-pointer"
                                 style="border-radius: 10px;">

                                <div [ngStyle]="{'background': last ? 'linear-gradient(180deg, rgba(20, 45, 37, 0) 0%, #142D25 100%)' : 'none'}"
                                     class="absolute w-[102%] h-[102%] -left-[2px] top-[2px]"></div>
                                <div [style]="'background-image:url(data:image/jpeg;base64,'+ job.asset.url +')'"
                                     class="mr-3 h-[80px] w-[80px] bg-contain border-1 surface-border rounded flex-shrink-0 bg-no-repeat bg-center">
                                </div>

                                <div>
                                    <div class="mt-0 mb-1 table table-fixed w-full">
                                        <p class="m-0 line-height-2 text-900 font-bold truncate">{{ job.title }}</p>
                                    </div>
                                    <div class="mt-0 mb-1 table table-fixed w-full">
                                        <p class="mt-0 mb-1 line-height-2 text-900 truncate">{{ job.subtitle }}</p>
                                    </div>
                                    <div class="mt-0 mb-1 table table-fixed w-full">
                                        <p class="mt-0 mb-0 line-height-2 text-600 truncate">{{ job.text }}</p>
                                    </div>
                                </div>
                            </div>

                            <div class="absolute bottom-5 w-full flex justify-content-center">
                                <a [routerLink]="['/opdrachten' | localize]" class="font-bold no-underline rounded-3xl shrink-0 flex align-items-center justify-content-center w-full md:w-auto mt-3 lg:mt-0"
                                        pButton
                                        pRipple>
                                    {{ "FIND_ALL_ASSIGNMENTS" | translate }}
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
